<template>
  <entity-form
    :id="withdrawal ? withdrawal.id : null"
    module-name="withdrawalsList"
    :model="form"
    :before-submit="beforeSubmit"
    @submit="onSubmit"
  >
    <b-form-row
      v-if="!withdrawal"
      class="my-1"
    >
      <b-col
        md="8"
        xs="12"
      >
        <entity-select
          v-model="form.account"
          :filter="accountFilter"
        />
      </b-col>
    </b-form-row>
    <span v-else>Account id: {{ withdrawal.account.account_id }}</span>

    <b-form-row class="my-1">
      <b-col
        md="8"
        xs="12"
      >
        <label>Withdrawal date & time</label>
        <flat-pickr
          v-model="form.withdrawal_datetime"
          class="form-control"
          :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
        />
      </b-col>
      <b-col
        md="8"
        xs="12"
      >
        <label>Profit share</label>
        <b-form-input
          v-model.number="form.profit_share"
          type="number"
          min="0"
          max="100"
        />
      </b-col>
      <b-col
        md="8"
        xs="12"
      >
        <label>Platform</label>
        <b-form-select
          v-model="form.platform"
          :disabled="withdrawal && withdrawal.hasOwnProperty('state') && withdrawal.state === 'completed'"
          :options="['plane', 'rise']"
        />
      </b-col>
    </b-form-row>
  </entity-form>
</template>
<script>
import {
  BFormRow, BCol, BFormInput, BFormSelect,
} from 'bootstrap-vue'

import FlatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import EntityForm from '@/components/ui/forms/EntityForm.vue'
import EntitySelect from '@/components/ui/gqlforms/EntitySelect.vue'

export default {
  components: {
    EntitySelect,
    BFormRow,
    BCol,
    BFormInput,
    BFormSelect,
    FlatPickr,
    EntityForm,
  },
  props: {
    title: String,
    withdrawal: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      form: {
        account: null,
        withdrawal_datetime: this.withdrawal ? new Date(this.withdrawal.withdrawal_datetime) : null,
        profit_share: this.withdrawal ? this.withdrawal.profit_share : null,
        platform: this.withdrawal ? this.withdrawal.platform : null,
      },
      accountFilter: {
        name: 'account',
        label: 'Account',
        filterable: {
          type: 'entity', queryType: 'accounts', label: 'account_id', search: 'account_id', value: 'id',
        },
      },
    }
  },
  methods: {
    errorMsg(msg) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Filed',
          icon: 'XCircleIcon',
          text: msg,
          variant: 'danger',
        },
      })
    },
    beforeSubmit() {
      if ((!this.withdrawal && this.form.account === null) || this.form.withdrawal_datetime === '' || this.form.profit_share < 0 || this.form.profit_share > 100) {
        this.errorMsg('Bad parameters')
        return false
      }
      this.form.withdrawal_datetime = new Date(this.form.withdrawal_datetime).toISOString()
      if (this.withdrawal) {
        this.form.account = undefined
      }
      return true
    },
    onSubmit(data) {
      this.$emit('submit', data)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
