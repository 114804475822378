const ACCOUNTS_SHORT = {
  e8: 'E8',
  e8trial: 'Trial',
  'trial-mtr': 'Trial MTR',
  e8tournament: 'Tournament',
  elev8: 'ELEV8',
  e8track: 'Track',
  e8track_eq: 'Track 1:1',
  e8one: 'E8 One',
  e8trialone: 'E8 One Trial',
  e8pro: 'E8 Pro',
  e8futures1: 'E8 Futures 1',
  e8futures2: 'E8 Futures 2',
  e8futures3: 'E8 Futures 3',
}

const ACCOUNTS = {
  e8one: {
    id: 'be431fc7-5fba-4ed2-a13b-318e76d03937',
    title: 'E8 One',
    icon: 'BarChart2Icon',
  },
  e8: {
    id: 'f67f4f37-0165-411c-94b7-09eb0b16db71',
    title: 'E8',
    icon: 'BarChart2Icon',
  },
  elev8: {
    id: 'a7e54906-b4a0-496d-8986-f32876bb0367',
    title: 'ELEV8',
    icon: 'TrendingUpIcon',
  },
  e8tournament: {
    id: 'c59c9f36-cb6d-4547-8914-52b2e2285777',
    title: 'E8 Tournament',
    icon: 'AwardIcon',
  },
  e8trial: {
    id: '6a2452ed-b344-40e6-ad02-81e681a22caa',
    title: 'E8 Trial',
    icon: 'CompassIcon',
  },
  e8trialone: {
    id: 'c887f14b-0470-4979-901e-7fd27c54365b',
    title: 'E8 One Trial',
    icon: 'CompassIcon',
  },
  e8track: {
    id: 'd2a3239b-03b0-44ba-9f5c-642bcf4e1f57',
    title: 'E8 Track',
    icon: 'CompassIcon',
  },
  e8track_eq: {
    id: '452a5dc3-2e09-40a6-8ce7-34a1912b2434',
    title: 'E8 Track 1:1',
    icon: 'CompassIcon',
  },
  e8pro: {
    id: '5b74b201-f760-481b-952d-6200a36c6f94',
    title: 'E8 Pro',
    icon: 'BarChart2Icon',
  },
  e8futures1: {
    id: '9ab0c24e-b60b-486f-b0ad-462f52fc7869',
    title: 'E8 Futures 1',
    icon: 'BarChart2Icon',
  },
  e8futures2: {
    id: '3841b7e5-23ab-478f-8df0-376244f01145',
    title: 'E8 Futures 2',
    icon: 'BarChart2Icon',
  },
  e8futures3: {
    id: '75eb56bb-116b-4a14-8409-c3b8e2742495',
    title: 'E8 Futures 3',
    icon: 'BarChart2Icon',
  },
}

const ACCOUNT_PHASES = {
  e8: {
    phase1: 'Phase One',
    phase2: 'Phase Two',
    funded: 'Funded',
  },
  e8one: {
    phase1: 'Phase One',
    funded: 'Funded',
  },
  e8trial: {
    trial: 'Trial',
  },
  e8trialone: {
    trial: 'Trial',
  },
  'trial-mtr': {
    trial: 'Trial',
  },
  e8tournament: {
    tickets: 'Ticket',
  },
  elev8: {
    phase1: 'Phase One',
    phase2: 'Phase Two',
    funded: 'Funded',
  },
  e8track: {
    phase1: 'Phase One',
    phase2: 'Phase Two',
    phase3: 'Phase Three',
    funded: 'Funded',
  },
  e8track_eq: {
    phase1: 'Phase One',
    phase2: 'Phase Two',
    phase3: 'Phase Three',
    funded: 'Funded',
  },
  e8pro: {
    phase1: 'Phase One',
    funded: 'Funded',
  },
  e8futures1: {
    phase1: 'Phase 1',
    funded: 'Funded',
  },
  e8futures2: {
    phase1: 'Phase 1',
    funded: 'Funded',
  },
  e8futures3: {
    phase1: 'Phase 1',
    phase2: 'Phase 2',
    phase3: 'Phase 3',
    phase4: 'Phase 4',
    funded: 'Live',
  },
}

const PHASES = {
  phase1: {
    store: 'phase1',
    name: 'phase1',
    title: 'Phase One',
  },
  phase2: {
    store: 'phase2',
    name: 'phase2',
    title: 'Phase Two',
  },
  phase3: {
    store: 'phase3',
    name: 'phase3',
    title: 'Phase Three',
  },
  phase4: {
    store: 'phase4',
    name: 'phase4',
    title: 'Phase Four',
  },
  funded: {
    store: 'funded',
    name: 'funded',
    title: 'Funded',
  },
  tickets: {
    store: 'tickets',
    name: 'tickets',
    title: 'tickets',
  },
}

const ACCOUNT_SUBTYPE = {
  phase1: {
    name: 'phase1',
    title: 'Phase One',
    variant: 'primary',
  },
  phase2: {
    name: 'phase2',
    title: 'Phase Two',
    variant: 'secondary',
  },
  phase3: {
    name: 'phase3',
    title: 'Phase Three',
    variant: 'secondary',
  },
  phase4: {
    name: 'phase4',
    title: 'Phase Four',
    variant: 'secondary',
  },
  funded: {
    name: 'funded',
    title: 'Funded',
    variant: 'danger',
  },
  trial: {
    name: 'trial',
    title: 'Trial',
    variant: 'info',
  },
  'trial-mtr': {
    name: 'trial',
    title: 'Trial',
    variant: 'info',
  },
  tickets: {
    store: 'tickets',
    name: 'tickets',
    title: 'tickets',
  },
}

const ACCOUNT_TYPES_BY_PHASE = [
  {
    id: 'phase1',
    label: 'Phase 1',
    children: [
      {
        id: 'e8onephase1',
        label: 'E8 One - Phase 1',
      },
      {
        id: 'e8prophase1',
        label: 'E8 Pro - Phase 1',
      },
      {
        id: 'e8phase1',
        label: 'E8 - Phase 1',
      },
      {
        id: 'elev8phase1',
        label: 'ELEV8 - Phase 1',
      },
      {
        id: 'e8trackphase1',
        label: 'E8 Track - Phase 1',
      },
      {
        id: 'e8futures1phase1',
        label: 'E8 Futures 1 - Phase 1',
      },
      {
        id: 'e8futures2phase1',
        label: 'E8 Futures 2 - Phase 1',
      },
      {
        id: 'e8futures3phase1',
        label: 'E8 Futures 3 - Phase 1',
      },
    ],
  },
  {
    id: 'phase2',
    label: 'Phase 2',
    children: [
      {
        id: 'e8phase2',
        label: 'E8 - Phase 2',
      },
      {
        id: 'elev8phase2',
        label: 'ELEV8 - Phase 2',
      },
      {
        id: 'e8trackphase2',
        label: 'E8 Track - Phase 2',
      },
      {
        id: 'e8futures3phase2',
        label: 'E8 Futures 3 - Phase 2',
      },
    ],
  },
  {
    id: 'phase3',
    label: 'Phase 3',
    children: [
      {
        id: 'e8trackphase3',
        label: 'E8 Track - Phase 3',
      },
      {
        id: 'e8track_eqphase3',
        label: 'E8 Track 1:1 - Phase 3',
      },
      {
        id: 'e8futures3phase3',
        label: 'E8 Futures 3 - Phase 3',
      },
    ],
  },
  {
    id: 'phase4',
    label: 'Phase 4',
    children: [
      {
        id: 'e8futures3phase4',
        label: 'E8 Futures 3 - Phase 4',
      },
    ],
  },
]

const STATE_VARIANTS = {
  active: 'primary',
  failed: 'danger',
  completed: 'success',
}

const ACCOUNT_RULES = {
  rule_max_days: 'Max days',
  rule_max_daily_drawdown: 'Max daily DD',
  rule_max_total_drawdown: 'Max initial DD',
  rule_min_trading_days: 'Min trading days',
  rule_min_profit: 'Min profit',
  rule_first_withdrawal_after: 'First withdrawal',
  rule_withdrawal_after: 'Withdrawal',
  rule_special: 'Special rules',
  profit_split: 'Profit split',
}

const INHERITANCE_RULES = [
  'max_days',
  'max_daily_drawdown',
  'max_total_drawdown',
  'min_trading_days',
  'min_profit',
  'first_withdrawal_after',
  'withdrawal_after',
  'special',
  'profit_split',
]

const ACCOUNT_SPECIAL_RULES = {
  'unlimited-days': 'Unlimited days',
  'ud-bonus-deposit': 'Unlimited days bonus',
}

const DRAWDOWN_TYPES_ENUM = [
  {
    label: 'Relative',
    value: 'relative',
  },
  {
    label: 'Initial daily',
    value: 'initial-daily',
  },
  {
    label: 'Balance based',
    value: 'initial-daily-simple',
  },
]

const DEFAULT_ACCOUNT_TYPES_SWITCHER = [
  { text: 'E8', value: 'e8' },
  { text: 'E8One', value: 'e8one' },
  { text: 'ELEV8', value: 'elev8' },
  { text: 'E8Track', value: 'e8track' },
  { text: 'E8Track1:1', value: 'e8track_eq' },
  { text: 'E8Pro', value: 'e8pro' },
  { text: 'E8Futures1', value: 'e8futures1' },
  { text: 'E8Futures2', value: 'e8futures2' },
  { text: 'E8Futures3', value: 'e8futures3' },
]

const TWO_PHASE_ACCOUNT_TYPES_SWITCHER = [
  { text: 'E8', value: 'e8' },
  { text: 'ELEV8', value: 'elev8' },
]

const DEFAULT_PLATFORMS_SWITCHER = ['MT4', 'MT5', 'MT5PURPLE', 'MT4PURPLE', 'THINK5', 'MTR', 'CTRADER', 'FUTURES']

const DEFAULT_FILTER_ACCOUNTS_TYPES = ['e8', 'elev8', 'e8track', 'e8track_eq', 'e8one', 'e8pro', 'e8futures1', 'e8futures2', 'e8futures3']

const PANDADOC_URL_PREFIX = 'https://app.pandadoc.com/a/#/documents/'

const AFF_ACCOUNTS_SELECTOR = [
  { text: 'All', value: null },
  { text: 'E8', value: 'e8' },
  { text: 'E8 Track', value: 'e8track' },
  { text: 'ELEV8', value: 'elev8' },
  { text: 'E8One', value: 'e8one' },
  { text: 'E8Pro', value: 'e8pro' },
  { text: 'E8 Tournament', value: 'e8tournament' },
]

const SPEND_VS_WITHDRAW_ACCOUNTS_FILTER = ['e8funded', 'elev8funded', 'e8trackfunded', 'e8onefunded', 'e8profunded']

const THREE_PHASE_ACCOUNTS = ['e8track', 'e8track_eq']

const EXECUTION_TYPES = ['stp', 'stp-sf', 'ecn', 'ecn-sf']

const SPECIAL_OPTIONS = ['payout_on_demand']

export {
  ACCOUNTS, ACCOUNTS_SHORT, ACCOUNT_PHASES, PHASES, STATE_VARIANTS, ACCOUNT_SUBTYPE, ACCOUNT_TYPES_BY_PHASE, ACCOUNT_RULES, ACCOUNT_SPECIAL_RULES, DRAWDOWN_TYPES_ENUM,
  DEFAULT_ACCOUNT_TYPES_SWITCHER, TWO_PHASE_ACCOUNT_TYPES_SWITCHER, DEFAULT_PLATFORMS_SWITCHER, DEFAULT_FILTER_ACCOUNTS_TYPES, PANDADOC_URL_PREFIX, AFF_ACCOUNTS_SELECTOR,
  SPEND_VS_WITHDRAW_ACCOUNTS_FILTER, THREE_PHASE_ACCOUNTS, EXECUTION_TYPES, SPECIAL_OPTIONS, INHERITANCE_RULES,
}
